import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ReimbursementService {

  constructor(
      private httpClient: HttpClient,
      private globals: Globals,
  ) { }
  private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

  getExpensesList(){
    return this.httpClient.get(this.globals.apiServerIP + '/getExpensesList')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

  }

  getfilterData(data:any){
    let params = new HttpParams();
		params = params.append('Emp_Id', data);
    return this.httpClient.get(this.globals.apiServerIP + '/getfilterData/', {params: params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
  }

  addClaim(model:any){
    return this.httpClient.post(this.globals.apiServerIP +'/addClaim', model)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  addSaveDraft(model:any){
    return this.httpClient.post(this.globals.apiServerIP +'/addSaveDraft', model)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getClaimsData(empid:any){
    let params = new HttpParams();
		params = params.append('Id', empid);
		return this.httpClient.get(this.globals.apiServerIP + '/getAllClaim', {params: params})
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
  }

  getAllExpenses(Claim_Id:number){
    let params = new HttpParams();
    params = params.append('Claim_Id', Claim_Id);
    return this.httpClient.get(this.globals.apiServerIP + '/getAllExpenses',{params: params})
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

  }

  getAllExpensesHistory(Claim_Id:number){
    let params = new HttpParams();
    params = params.append('Claim_Id', Claim_Id);
    return this.httpClient.get(this.globals.apiServerIP + '/getAllExpensesHistory',{params: params})
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

  }

  getAllComments(Claim_Id:number){
    let params = new HttpParams();
    params = params.append('Claim_Id', Claim_Id);
    return this.httpClient.get(this.globals.apiServerIP + '/getAllComments',{params: params})
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);

  }

  UpdateClaimStatus(params:any){
    return this.httpClient.put(this.globals.apiServerIP + '/UpdateClaimStatus', params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
  }
  
  updateClaim(model : any){
		return this.httpClient.put(this.globals.apiServerIP +'/updateClaim', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	updateSaveDraft(model : any){
		return this.httpClient.put(this.globals.apiServerIP +'/updateSaveDraft', model)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

  addSettingData(data:any){
    return this.httpClient.post(this.globals.apiServerIP +'/addSettingData', data)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }


  getSettingData(){
    return this.httpClient.get(this.globals.apiServerIP + '/getSettingtabData')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);

  }

  deleteApprover(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/deleteApproverData', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

  changeExpensesStatus(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/changeExpensesStatus', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}


  addExpenses(data:any){
    return this.httpClient.post(this.globals.apiServerIP +'/addExpenses', data)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  updateExpenses(data:any){
    return this.httpClient.post(this.globals.apiServerIP +'/updateExpenses', data)
    .pipe(
      map((response: any) => {
        return response;
      }),
      catchError(this.handleError)
    );
  }

  getReimbursementApproverData(data : any = {}){
    return this.httpClient.get(this.globals.apiServerIP + '/getReimbursementApproverData/', {params: data})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
  }


	updateClaimApprovals(params:any){
		if(params.Status == 2){
			return this.httpClient.put(this.globals.apiServerIP + '/UpdateClaimManagersApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else if(params.Status == 3){
			return this.httpClient.put(this.globals.apiServerIP + '/sendClaimToDirectorApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		

		else if(params.Status == 4){
			return this.httpClient.put(this.globals.apiServerIP + '/updateClaimFinanceFirstLevelApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}

		else if(params.Status == 5){
			return this.httpClient.put(this.globals.apiServerIP + '/updateClaimFinanceFinalLevelApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else if(params.Status == -4){
			return this.httpClient.put(this.globals.apiServerIP + '/RevertClaimfromFinance', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else if(params.Status == -1){
			return this.httpClient.put(this.globals.apiServerIP + '/RevertClaim', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else if(params.Status == 6){
			return this.httpClient.put(this.globals.apiServerIP + '/AccountingClaim', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else if(params.Status == 7){
			return this.httpClient.put(this.globals.apiServerIP + '/PaidClaim', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else if(params.Status == -6){
			return this.httpClient.put(this.globals.apiServerIP + '/RevertClaimFromFinanceFinalApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		}
		else{
		return this.httpClient.put(this.globals.apiServerIP + '/RejectClaimApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
	  }
	}

    updateClaimDirectorsApprovals(params:any){
		//  if(params.Upcoming_Status == 4){
			return this.httpClient.put(this.globals.apiServerIP + '/updateClaimDirectorsApproval', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
		// }
		
	}

	updateClaimApprovalsReject(params:any){
		return this.httpClient.put(this.globals.apiServerIP + '/RejectClaimAtFinance', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);

	}

	getCommentdropdown(){
        return this.httpClient.get(this.globals.apiServerIP + '/getCommentdropdown')
                .pipe(
                    map((response: any) => {
                        return response[0].data;
                    }),
                    catchError(this.handleError)
                );
      }
 
    getDownloadexpenses(data:any){
		const formattedData = data.join(',');
        return this.httpClient.get(this.globals.apiServerIP + '/getDownloadexpenses', { params: { Claim_Id: formattedData } })
                .pipe(
                    map((response: any) => {
                        return response[0].data;
                    }),
                    catchError(this.handleError)
                );
      }
	  getDownloadApproverexpenses(data:any){
		const formattedData = data.join(',');
        return this.httpClient.get(this.globals.apiServerIP + '/getDownloadApproverexpenses', { params: { Claim_Id: formattedData } })
                .pipe(
                    map((response: any) => {
                        return response[0].data;
                    }),
                    catchError(this.handleError)
                );
      }
 
      addComment(data:any){
        return this.httpClient.post(this.globals.apiServerIP +'/addComment', data)
        .pipe(
          map((response: any) => {
            return response;
          }),
          catchError(this.handleError)
        );
      }
 
      deleteComment(Id: any) {
        return this.httpClient.post(this.globals.apiServerIP + '/deleteComment', Id)
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError(this.handleError)
            );
    }
	BulkStatusChange(data:any){
		return this.httpClient.post(this.globals.apiServerIP +'/BulkStatusChange', data)
		.pipe(
		  map((response: any) => {
			return response;
		  }),
		  catchError(this.handleError)
		);
	  }

	changeStatus(params:any){
			return this.httpClient.post(this.globals.apiServerIP + '/changeStatus', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
	}

	ChangeAccountDoneStatus(params:any){
			return this.httpClient.post(this.globals.apiServerIP + '/ChangeAccountDoneStatus', params)
				.pipe(
					map((response: any) => {
						return response;
					}),
					catchError(this.handleError)
				);
	}

	addCurrencyAmount(data:any){
		return this.httpClient.post(this.globals.apiServerIP +'/addCurrecnyLimitationAmount', data)
		.pipe(
		  map((response: any) => {
			return response;
		  }),
		  catchError(this.handleError)
		);
	  }

	updateCurrencyAmount(data:any){
		return this.httpClient.post(this.globals.apiServerIP +'/updateCurrencyLimitationAmount', data)
		.pipe(
		  map((response: any) => {
			return response;
		  }),
		  catchError(this.handleError)
		);
	  }

	  getTeamOutingList(){
		return this.httpClient.get(this.globals.apiServerIP + '/getTeamOutingList')
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	  }

	  attendeeEligAmount(Claim_Id:any){
		let params = new HttpParams();
		params = params.append('Claim_Id', Claim_Id);
   	    // params = params.append('Reciept_Date', Reciept_Date);
		return this.httpClient.get(this.globals.apiServerIP + '/attendeeEligAmount',{params:params})
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			);
	  }

	 
}
