import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { forkJoin } from 'rxjs';
import { Globals } from '../../global';
import { EmployeeIdNameService } from '../employeeidname/employeeidname.service';
import { catchError, map } from 'rxjs/operators';

@Injectable()
export class CommonDataStreamService {
    
    private baseURL = this.globals.apiBaseURL + '/';

    dataStream : any;

    constructor(
        private httpClient: HttpClient,
        private globals: Globals,
        private employeeIdNameService: EmployeeIdNameService
    ){}

    commonDataStream() {
        var url1 = this.baseURL + 'basicDetails/';
        return this.httpClient.get(url1)
        .pipe(
            map((response: any) => {
                this.dataStream = response[0].data[0].data;
                /* To avoid getting the list again from API. We can just filter the non PIP types for the utilization. */
                var resTypes = this.dataStream.resourceTypes.slice(0);
                this.dataStream.utilizationresourceType = resTypes.filter((obj: { Type: string; }) => obj.Type != 'PIP');
                return response[0].data;
            }),
            catchError(this.handleError)
        );
    }

    fetchPrerequistes() {
      let basicDetailsURL = this.baseURL + 'basicDetails/';
      let empIDDetailsURL = this.baseURL + 'employeeIdName/';

      let getBasicDetails = this.httpClient.get(basicDetailsURL);
      let getEmpIdNameDetails = this.httpClient.get(empIDDetailsURL);

      return forkJoin([getBasicDetails, getEmpIdNameDetails])
      .pipe(
      map((response:any) => {
        if ( response && response.length >= 2 ) {
          this.dataStream = response[0][0].data[0].data;
          var empIdDetails = response[1][0].data[0].data;
          this.employeeIdNameService.empIdNames = empIdDetails;
          this.employeeIdNameService.active_employees = [];
          this.employeeIdNameService.active_employees = empIdDetails.filter((obj: { Current_Status: string; }) => obj.Current_Status === 'Active' || obj.Current_Status === 'Notice');

          return true;
        }
        return false;
      }),
      catchError(this.handleError)
      );
    }

    getCompanyDesignations(filterData:any) {
        let params = new HttpParams();
        params = params.append('Desg_Id', filterData.Desg_Id);
		params = params.append('Dept_Id', filterData.Dept_Id);
		params = params.append('Grp_Id', filterData.Grp_Id);
		params = params.append('SubGrp_Id', filterData.SubGrp_Id);
		params = params.append('level_Id', filterData.level_Id);
		return this.httpClient.get(this.baseURL + 'api/getDesignations/',{params:params})
        .pipe(
            map((response: any) => {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyDesignations(modelData: {}) {
        var urlDesignations = this.baseURL + 'api/addDesignations/' ;
        return this.httpClient.post(urlDesignations,modelData)
        .pipe(
            map((response: any) => {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    editCompanyDesignations(modelData: {}) {
        var urlDesignations = this.baseURL + 'api/editDesignations/' ;
        return this.httpClient.post(urlDesignations,modelData)
        .pipe(
            map((response: any) => {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyGroups(modelData: {}) {
        var urlGroups = this.baseURL + 'api/addGroups/' ;
        return this.httpClient.post(urlGroups,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyGroups(modelData: {},id:any) {
        var urlGroups = this.baseURL + 'api/updateGroups/'+ id;
        return this.httpClient.put(urlGroups,modelData) 
         .pipe(
            map((response: any) => 
            {
            return response;    
            }),
            catchError(this.handleError)
        );
    }

    addCompanySubGroups(modelData: {}) {
        var urlSubGroups = this.baseURL + 'api/addSubGroups/' ;
        return this.httpClient.post(urlSubGroups,modelData)
        .pipe(
            map((response: any) => {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateCompanySubGroups(modelData: {},id:any) {
        var urlSubGroups = this.baseURL + 'api/updateSubGroups/'+id;
        return this.httpClient.put(urlSubGroups, modelData)
         .pipe(
            map((response: any) => {
            return response;   
            }),
            catchError(this.handleError)
        );
    } 

    addCompanyLevels(modelData: {}) {
        var urlLevels = this.baseURL + 'api/addLevels/';
        return this.httpClient.post(urlLevels,modelData)
        .pipe(
            map((response:any) => {
              return response;
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyLevels(modelData: {},id:any) {
        var urlLevels = this.baseURL + 'api/addLevels/';
        return this.httpClient.put(urlLevels,modelData)
         .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    addAppraisalRatings(modelData: {}) {
        var url = this.baseURL + 'api/addAppraisalRatings/';
        return this.httpClient.post(url,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    //Admin Bank Related Methods
    addcompanyListedBank(modelData: {}) {
        var urlBank = this.baseURL + 'api/addBank/';
        return this.httpClient.post(urlBank,modelData)
       .pipe(
           map((response: any) => {
            return response;
           }),
           catchError(this.handleError)
       );
    }

    updatecompanyListedBank(modelData: {},id:any) {
        var urlBank = this.baseURL + 'api/updateBank/'+ id ;
        return this.httpClient.put(urlBank,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    deleteCompanyListedBanks(id:any) {
        var urlSubGroups = this.baseURL + 'api/deletebank/' + id;
        return this.httpClient.delete(urlSubGroups)
            .pipe(
                map((response: any) => {
                    return response;
                }),
                catchError(this.handleError)
            );
    }

    //Admin Country Related Methods
    addCompanyListedCountry(modelData: {}) {
        var urlCountry = this.baseURL + 'api/addCountry/';
        return this.httpClient.post(urlCountry,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyListedCountry(modelData: {},id:any) {
        var urlCountry = this.baseURL + 'api/updateCountry/'+ id;
        return this.httpClient.put(urlCountry,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyListedCourse(modelData: {}) {
        var urlCourse = this.baseURL + 'api/addCourse/';
        return this.httpClient.post(urlCourse,modelData)
        .pipe(
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyListedCollege(modelData: {}) {
        var urlCollege = this.baseURL + 'api/addCollege/';
        return this.httpClient.post(urlCollege,modelData)
        .pipe(
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyListedSkills(modelData: {}) {
        var urlSkill = this.baseURL + 'api/addSkill/';
        return this.httpClient.post(urlSkill,modelData)
        .pipe(
            map((response: any) => {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyListedSkills(modelData: {},id:any) {
        var urlSkill = this.baseURL + 'api/updateSkill/'+ id;
        return this.httpClient.put(urlSkill,modelData)
        .pipe(
            map((response: any) => {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyListedHolidays(modelData: {}) {
        var urlUniversity = this.baseURL + 'api/addHolidays/';
        return this.httpClient.post(urlUniversity,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyListedHolidays(modelData: {},id:any) {
        var urlUniversity = this.baseURL + 'api/updateHolidays/'+ id;
        return this.httpClient.put(urlUniversity,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    getCompanyListedHolidays() {
        var holidayUniversity = this.baseURL + 'api/getHolidays/';
        return this.httpClient.get(holidayUniversity)
        .pipe( 
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    addCompanyListedState(modelData: {}) {
        var urlState = this.baseURL + 'api/addState/';
        return this.httpClient.post(urlState,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyListedState(modelData: {},Id : any) {
        var urlState = this.baseURL + 'api/updateState/'+ Id;
        return this.httpClient.put(urlState,modelData)
        .pipe (
            map((response: any) =>
            {
                return response;
            }),
            catchError(this.handleError)
        );
    }

    addCompanyListedCity(modelData: {}) {
        var urlCities = this.baseURL + 'api/addCity/';
        return this.httpClient.post(urlCities,modelData)
        .pipe(
            map((response:any) =>  {
                return response;
            }),
            catchError(this.handleError)
        );
    }

	updatecompanyListedCity(modelData: {},Id : any) {
        var urlState = this.baseURL + 'api/updateCity/'+ Id;
        return this.httpClient.put(urlState,modelData)
        .pipe(
            map((response:any) => {
                return response;
            }),
            catchError(this.handleError)
        );
    } 
   
    addCompanyDepartments(modelData: {}) {
        var urlDepartments = this.baseURL + 'api/addDepartment/';
        return this.httpClient.post(urlDepartments,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateCompanyDepartments(modelData: {}, dId: any) {
        var urlDepartments = this.baseURL + 'api/updateDepartment/' + dId;
        return this.httpClient.put(urlDepartments,modelData)
          .pipe(
            map((response: any) => {
                return response; 
            }),
            catchError(this.handleError)
        );
    }

    groupStatusChange(params: any ={}){
		var urlGroups = this.baseURL + 'api/groupStatusChange/';
		return this.httpClient.post(urlGroups, params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

    updateAppDataConfig(modelData: {}) {
        var urlAppConfig = this.baseURL + 'api/updateAppDataConfig/';
        return this.httpClient.post(urlAppConfig,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updateSowApprover(modelData: {}) {
        var urlAppConfig = this.baseURL + 'api/updateSowApprover/';
        return this.httpClient.post(urlAppConfig,modelData)   
        .pipe(
            map((response: any) => {
               return response;
            }),
            catchError(this.handleError)
        );

    }

    deleteSowApprover(id:any) {
        var urlAppConfig = this.baseURL + 'api/deleteSowApprover/'+ id;
        return this.httpClient.delete(urlAppConfig)
        .pipe(
            map((response:any) => {
                return response[0].data;
            }),
            catchError(this.handleError)
        )
    }

    addSowApprover(modelData: {}) {
        var urlAppConfig = this.baseURL + 'api/addSowApprover/';
        return this.httpClient.post(urlAppConfig,modelData)
        .pipe(
            map((response:any) => {
                return response; 
            }),
            catchError(this.handleError)
        )
    }
   
    getTrainingCourse() {
        var course = this.baseURL + 'api/getTrainingCourse/';
        return this.httpClient.get(course)
        .pipe( 
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    getLMSCourseList() {
        var list = this.baseURL + 'api/getLmsCourseList/';
        return this.httpClient.get(list)
        .pipe( 
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }
   
    updateTrainingCourse(modelData: {}) {
        var urlCourse = this.baseURL + 'api/updateTrainingCourse/';
        return this.httpClient.put(urlCourse,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    addAmpAccess(modalData: {}) {
        var ampAccess = this.baseURL + 'api/addAmpAccess/';
        return this.httpClient.post(ampAccess,modalData)
        .pipe(
            map((response: any) => 
            {
                return response;   
            }),
            catchError(this.handleError)
        );
    }

    getAmpAccessData() {
        var ampAccess = this.baseURL + 'api/getAmpAccess/';
        return this.httpClient.get(ampAccess)
        .pipe( 
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    ampAccessStatusChange(params: any ={}){
		var urlGroups = this.baseURL + 'api/ampAccessStatusChange/';
		return this.httpClient.post(urlGroups, params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

    deleteAmpAccess(id:number){
        var ampAccess = this.baseURL + 'api/deleteAmpAccess/';
        return this.httpClient.post(ampAccess,id)
        .pipe(
            map((response: any) => 
            {
                return response;   
            }),
            catchError(this.handleError)
        );
    }

    sendNewJoineeMailsToEmployee(params: any ={}){
		var urlGroups = this.baseURL + 'api/triggerNewJoineeToEmployee/';
		return this.httpClient.post(urlGroups, params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

    sendNewJoineeMailsToManager(params: any ={}){
		var urlGroups = this.baseURL + 'api/triggerNewJoineeToManager/';
		return this.httpClient.post(urlGroups, params)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}
    getPerdiem() {
        var urlgetperdiem = this.baseURL + 'api/getPerdiemData/';
        return this.httpClient.get(urlgetperdiem)
        .pipe(
            map((response: any) => 
            {
            return response[0].data;   
            }),
            catchError(this.handleError)
        );
    }

    addPerdiem(modelData: {}) {
        var urlperdiem = this.baseURL + 'api/addPerdiem/';
        return this.httpClient.post(urlperdiem,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }

    updatePerdiem(modelData: {}) {
        var urlperdiem = this.baseURL + 'api/updatePerdiem/';
        return this.httpClient.post(urlperdiem,modelData)
        .pipe(
            map((response: any) => 
            {
            return response;   
            }),
            catchError(this.handleError)
        );
    }


    private handleError(error: any): Promise<any> {
        console.error('An error occurred while fetching the employee details.', error); // for demo purposes only
        return Promise.reject(error.message || error);
    }

    isSuperAdmin() : boolean {
        return  this.globals.getLoginUserRole()  === 1;
    }

    isHR() : boolean {
        return  this.globals.getLoginUserRole()  === 2;
    }

    isManager() : boolean {
        return  this.globals.getLoginUserRole()  === 4;
    }

    isEmployee() : boolean {
        return  this.globals.getLoginUserRole()  === 8;
    }

    isLoginUser( employeeid: string ) : boolean {
        return employeeid ===  this.globals.getLoginUsername() ;
    }



}