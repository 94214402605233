import { Injectable } from '@angular/core';
import { HttpClient, HttpParams} from '@angular/common/http';
import { Globals } from '../../global';
import { catchError, map } from 'rxjs';


@Injectable()
export class SkilltrackerService {
	service_url = this.globals.apiBaseURL

	constructor(private httpClient: HttpClient,
		private globals: Globals,
	) { }
	private handleError(error: any): Promise<any> {
		// console.error('An error occurred while fetching the details.', error); // for demo purposes only
		return Promise.reject(error.message || error);
	}

	// getPrimaryKeys(): Observable<any[]>  {
	//    return Observable.of(PRIMARY_KEY).delay(100);
	//  }

	getReportColumns(): string[] {
		return ["Sl No", "Skills", "Matrix Score", "Longivity Score", "Experience Score", "Skill Score", "Evaluated", "Evaluated on"];
	}

	//For Getting Current Qtr Skills
	getLatestSkillDetails(data: any) {
		return this.httpClient.post(this.service_url + '/getDefaultSkillDetails', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	
	//get horizon skills list
	getHorizonskillsDetails(data: any) {
		return this.httpClient.post(this.service_url + '/getHorizonSkills', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}


	//For History Qtr Skills
	getHistorySkillDetails(data: any) {
		return this.httpClient.post(this.service_url + '/getSkillDetailsHistory', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}
	//save previous quarter data ifno updates
	savePreviousQuarterdata(data: any) {
		return this.httpClient.post(this.service_url + '/updateSkillDataTimestamp', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}
	//get previous qtr data to current qtr

	//For Saving Skills into DB
	saveSkillInformation(data: any) {
		return this.httpClient.post(this.service_url + "/addSkillTracker", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	deletependingskills(id: any) {
		return this.httpClient.post(this.service_url + '/deleteSkillTracker', id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	};

	//GET Employee List
	getApprovalEmployeeList(data: any) {
		return this.httpClient.post(this.service_url + '/getApprovalEmployeeList', data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	//For Saving Skills into DB
	saveApprovalData(data: any) {
		return this.httpClient.post(this.service_url + "/approveSkillTracker", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	//For Getting Designation for Report Filters
	getDesignation() {
		return this.httpClient.get(this.service_url + "/getDesignationList")
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			)
	}

	//For Getting Skills for Report Filters
	getSkillsList(data: any) {
		return this.httpClient.post(this.service_url + "/getSkillsList", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	//For Getting Employee for Report Filters
	getReportEmployeeList(data: any) {
		return this.httpClient.post(this.service_url + "/getReportEmployeeList", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}
	//For getting the employees in recruitment team
	// getRecruitEmployeeList(){
	//   return this.httpClient.get(this.service_url + "/getRecruitEmployeeList?token="+ this.globals.getLoginUserToken()).toPromise()
	//   .then(response => {
	//       return response;
	//   })
	//   .catch(this.handleError);
	//
	// }
	//For Generating Report
	getReport(data: any) {
		return this.httpClient.post(this.service_url + "/getReport", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	//For Generating Report
	getChart(data: any) {
		return this.httpClient.post(this.service_url + "/getChart", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}
	//For Getting Score Weights
	getScoreWeight() {
		return this.httpClient.get(this.service_url + "/getScoreWeight")
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			)
	}

	getReminderEmailDates(data:any) {
		// console.log(selectedquater)
		return this.httpClient.post(this.service_url + "/getReminderEmailDates",data)
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			)
	}

	saveReminderDates(data:any){
		return this.httpClient.post(this.service_url + "/SaveReminderDates", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	//For Update Score Weight
	UpdateScoreWeight(data: any) {
		return this.httpClient.post(this.service_url + "/UpdateScoreWeight", data)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			)
	}

	currentdeployement() {
		return this.httpClient.get(this.service_url + "/Currentdeployment")
			.pipe(
				map((response: any) => {
					return response[0].data;
				}),
				catchError(this.handleError)
			)
	}

	/* To get the filled, non-filled, L1-L3 offshore resources data */
	getFilledReport(currentQtr: any) {
		return this.httpClient.post(this.service_url + "/api/getFilledReport",currentQtr)
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		)
	}

	getSkillTrackerPermissions() {
		return this.httpClient.get(this.service_url + "/getSkillTrackerPermissions?")
		.pipe(
			map((response: any) => {
				return response;
			}),
			catchError(this.handleError)
		)
	}

	deleteCertificateData(Id: any) {
		return this.httpClient.post(this.globals.apiServerIP + '/api/deleteCertificateData', Id)
			.pipe(
				map((response: any) => {
					return response;
				}),
				catchError(this.handleError)
			);
	}

	/* To get the primary, horizon and certificate data. */
	getSkillReport(data: any) {
		let params = new HttpParams();
        params = params.append('empId', data.emp_id);
        params = params.append('groupId', data.group_id);
        params = params.append('qtr', data.qtr);
        params = params.append('skillId', data.skill_id);
		return this.httpClient.get(this.service_url + "/gelSkillReports", {params: params})
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		)
	}

	/* To fetch all the skill set. */
	getAllSkillList() {
		return this.httpClient.get(this.service_url + "/getAllSkillsList")
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		)
	}

	/* To fetch the groups */
	getGroupList() {
		return this.httpClient.get(this.service_url + "/getGroupList")
		.pipe(
			map((response: any) => {
				return response[0].data;
			}),
			catchError(this.handleError)
		)
	}
}
